<template>
  <section class="testimonials section-padding bg-prop section-overlay gradient text-center">
    <div class="container">

      <div class="row">

        <div class="col-lg-8 mx-auto">
          <div class="owl-carousel owl-theme">
            <!-- Testimonials Item -->
            <div class="item">
              <p>We work with energy and passion for creative and innovative digital solutions. We engage users with inspiring experiences is the zeal behind what we do for our clients.</p>
            </div>
          </div>
        </div>

      </div><!-- /row -->
    </div><!-- /container -->
  </section>
</template>

<script>
export default {
	name: 'Testimonials'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .testimonials{
    background-image: url('../assets/img/bg2.jpg');
  }
</style>
