<template>
  <footer class="footer text-center">
    <div class="container">

      <!-- Main Footer -->
      <div class="main-footer">

        <a class="logo" href="/#" data-scroll-nav="0">
          <img src="@/assets/img/logo-light.png" alt="logo" />
        </a>

        <!-- Social Media Icons-->
        <!--<div class="social-media">-->
          <!--<a href="index.html#0"><span><i class="ti-facebook"></i></span></a>-->
          <!--<a href="index.html#0"><span><i class="ti-twitter-alt"></i></span></a>-->
          <!--<a href="index.html#0"><span><i class="ti-linkedin"></i></span></a>-->
          <!--<a href="index.html#0"><span><i class="ti-pinterest"></i></span></a>-->
          <!--<a href="index.html#0"><span><i class="ti-instagram"></i></span></a>-->
        <!--</div>-->

      </div><!-- /main-footer -->

      <!-- Copyright -->
      <p class="copyright">Copyright &copy; 2019 Double O.</p>

    </div><!-- /container -->
  </footer>
</template>

<script>
export default {
	name: 'Footer'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
