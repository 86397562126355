<template>
  <div class="home">
    <!-- ===== Start Header ===== -->
    <Intro />
    <!-- ===== End Header ===== -->
    <!-- ===== Start About ===== -->
    <About />
    <!-- ===== End About ===== -->
    <!-- ===== Start Services ===== -->
    <Services />
    <!-- ===== End Services ===== -->
    <!-- ===== Start Testimonials ===== -->
    <Testimonials />
    <!-- ===== End Testimonials ===== -->

	<Portfolio />
    <!-- ===== Start Clients ===== -->
    <Clients />
    <!-- ===== End Clients ===== -->
    <!-- ===== Start Contact ===== -->
    <Contact />
    <!-- ===== End Contact ===== -->
    <!-- ===== Start Footer ===== -->
    <Footer />
    <!-- ===== End Footer ===== -->
  </div>
</template>
<style>
/* .header{
  background: url('../assets/img/bg1.jpg');
} */
</style>
<script>
// @ is an alias to /src
import Intro from '@/components/Intro.vue'
import About from '@/components/About.vue'
import Services from '@/components/Services.vue'
import Testimonials from '@/components/Testimonials.vue'
import Clients from '@/components/Clients.vue'
import Contact from '@/components/Contact.vue'
import Footer from '@/components/Footer.vue'
import Portfolio from '@/components/Portfolio.vue'

export default {
	name: 'home',
	components: {
		Intro,
		About,
		Services,
		Testimonials,
		Portfolio,
		Clients,
		Contact,
		Footer
	}
}
</script>
