<template>
  <section id="contact" class="contact text-center">
    <div class="container">
      <div class="contact-info" data-scroll-index="6">
        <div class="row no-gutters">

          <!-- Contact Info Item -->
          <div class="item col-lg-4 mb-md-50">
            <span class="icon"><i class="ti-mobile"></i></span>
            <h6>Phone</h6>
            <p><a href="tel:+84888006840">(+84) 888 00 68 40</a></p>
          </div>

          <!-- Contact Info Item -->
          <div class="item col-lg-4 mb-md-50">
            <span class="icon"><i class="ti-map-alt"></i></span>
            <h6>Address</h6>
            <p>68 Nguyen Hue, Dist 1, Ho Chi Minh City</p>
          </div>

          <!-- Contact Info Item -->
          <div class="item col-lg-4">
            <span class="icon"><i class="ti-email"></i></span>
            <h6>Email</h6>
            <p><a href="mailto:contact@doubleo.io">contact@doubleo.io</a></p>
          </div>

        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
	name: 'Contact'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
