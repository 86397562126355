<template>
  <section id="portfolio" class="portfolio pb-5 pt-5 section-padding" data-scroll-index="3">
    <div class="container">

      <div class="row">

        <div v-for="(client, i) in clients" v-bind:key="i" class="col-12 col-md-6 item web">
					<div class="project-grid project-style-2">
							<div class="thumbnail">
									<a :href="client.url" target="_blank">
											<img :src="client.image" :alt="client.name">
									</a>
							</div>
							<div class="content">
									<span class="subtitle">{{ client.subtitle }}</span>
									<h3 class="title"><a href="single-portfolio.html">{{ client.name }}</a></h3>
							</div>
					</div>

        </div>
      </div>
			<div class="text-center mt-5">
				<a href="https://drive.google.com/file/d/1N9xpbN4V61i69a3J72Dv7ONEmKLYaSr_/view?usp=sharing" target="_blank" title="View portfolio" >View More </a>
			</div>
    </div><!-- /container -->
  </section>
  <!-- ===== End Portfolio ===== -->
</template>

<script>
const getImage = img => require('@/assets/img/port/' + img)

export default {
	name: 'project-list',
	data: () => ({
		clients: [
			{
				image: getImage('amakuni.jpg'),
				name: 'Amakuni.com',
				subtitle: 'Web, Design, Blockchain',
				url: 'https://amakuni.com'
			},
			{
				image: getImage('neverendstudio.jpg'),
				name: 'Neverendstories.com',
				subtitle: 'Web, Design',
				url: 'https://neverendstories.com/'
			},
			{
				image: getImage('isd.jpg'),
				name: 'ISD Engeering',
				subtitle: 'Web, Design',
				url: 'https://isd-engineering.com/'
			},
			{
				image: getImage('giaolong2.jpg'),
				name: 'Giao Long Pharma',
				subtitle: 'Web, Design',
				url: 'https://giaolongpharma.vn/'
			}
		]
	})
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	.project-grid {
    box-shadow: 0 55px 86px -35px #ecf2f6;
    border-radius: 30px;
    margin-bottom: 40px;
}
.project-grid .thumbnail {
    overflow: hidden;
    border-radius: 30px 30px 0 0;
    position: relative;
}
.project-grid .thumbnail img {
    border-radius: 30px 30px 0 0;
    width: 100%;
    -webkit-transition: var(--transition);
    transition: var(--transition);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: -webkit-transform 2s cubic-bezier(0.2, 0.96, 0.34, 1);
    transition: -webkit-transform 2s cubic-bezier(0.2, 0.96, 0.34, 1);
    transition: transform 2s cubic-bezier(0.2, 0.96, 0.34, 1);
    transition: transform 2s cubic-bezier(0.2, 0.96, 0.34, 1), -webkit-transform 2s cubic-bezier(0.2, 0.96, 0.34, 1);
}
.project-grid .content {
    background-color: var(--color-white);
    border-radius: 0 0 30px 30px;
    padding: 40px 50px;
}
.project-grid:hover .thumbnail img {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
}
.project-grid.project-style-2 {
    box-shadow: none;
    position: relative;
    margin-bottom: 60px;
}
.project-grid.project-style-2 .thumbnail {
    border-radius: 30px;
}
.project-grid.project-style-2 .thumbnail img {
    border-radius: 30px;
}
.project-grid.project-style-2 .content {
    background-color: transparent;
    border-radius: 0;
    padding: 0;
    position: absolute;
    top: 50px;
    left: 50px;
}
.project-grid.project-style-2 .content .subtitle {
    font-size: 18px;
    margin-bottom: 15px;
}
.project-grid .content .title {
    letter-spacing: -0.045em;
    margin-bottom: 10px;
}
</style>
