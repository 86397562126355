<template>
  <!-- <nav class="navbar navbar-expand-md"> -->
  <scrollactive class="navbar navbar-expand-md" active-class="active" :always-track=true :offset=80>
      <div class="container">

        <!-- Logo and toggle get grouped for better mobile display -->
        <a class="logo" href="/#">
<!--          Double O-->
          <img src="@/assets/img/logo-light.png" id="logoLight" alt="Double O">
          <img src="@/assets/img/logo-dark.png" style="display:none;" id="logoDark" alt="Double O">
        </a>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="menu-icon"><i class="ti-menu"></i></span>
        </button>

        <!-- Collect the nav links, and other content for toggling -->
        <div class="collapse navbar-collapse" id="navbarNav">

          <!-- Links -->
          <ul class="navbar-nav ml-auto">
            <li class="nav-item active">
              <a class="nav-link  scrollactive-item active" href="/#home" data-section-selector="#home" data-scroll-nav="0">Home <span class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item">
              <a class="nav-link scrollactive-item" href="/#about" data-section-selector="#about" data-scroll-nav="1">About</a>
            </li>
            <li class="nav-item">
              <a class="nav-link scrollactive-item" href="/#services" data-section-selector="#services" data-scroll-nav="2">Services</a>
            </li>
            <li class="nav-item">
              <a class="nav-link scrollactive-item" href="/#clients" data-section-selector="#clients" data-scroll-nav="3">Clients</a>
            </li>
            <li class="nav-item">
              <a class="nav-link scrollactive-item" href="/#contact" data-section-selector="#contact" data-scroll-nav="6">Contact</a>
            </li>
          </ul>

        </div><!-- /navbar-collapse -->
      </div><!-- /container -->
    </scrollactive>
    <!-- </nav> -->
</template>

<script>
export default {
	name: 'AppNavigation'
}
</script>

<style scoped>
</style>
