<template>
  <div id="app">
    <!-- ===== Start Preloader ===== -->
    <div class="loading">
      <div class="v-middle"><div class="load-circle"></div></div>
    </div>
    <!-- ===== End Preloader ===== -->

    <!-- === Start Button Top === -->
    <div class="button-top" data-scroll-nav="0">
      <span><i class="ti-angle-up"></i></span>
    </div>
    <!-- ===== End Button Top ===== -->
    <Header />
    <!-- <header id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/#about">About</router-link>
    </header> -->
    <router-view/>
  </div>
</template>

<style lang="scss">
@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import 'assets/scss/bootstrap-custom.scss';
@import 'assets/themify-icons/themify-icons.css';
@import 'assets/scss/style.scss';
#app {
  // font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

<script>
import Header from '@/components/Header.vue'
export default {
	components: {
		Header
	}
}
</script>
