<template>
  <section id="about" class="about section-padding" data-scroll-index="1">
    <div class="container">
      <div class="row">

        <!-- Intro -->
        <div class="col-lg-7">
          <!-- Section Heading  -->
        <div class="section-head">
          <h3>About Us</h3>
          <h6>Website design and development services</h6>
        </div>
          <div class="intro mb-md-50">
            <p>Double O is a full-service digital and creative agency based in Ho Chi Minh City, Vietnam. We are a group of young-minded professionals who specialize in responsive design and innovative platforms, dedicated to delivering effective, measurable and creative digital solutions to our clients.</p>

            <!-- Some Details -->
            <!--<div class="details row">-->
              <!--<div class="item col-sm-6">-->
                <!--<p><span class="icon"><i class="ti-calendar"></i></span>Age: 29</p>-->
              <!--</div>-->
              <!--<div class="item col-sm-6">-->
                <!--<p><span class="icon"><i class="ti-map"></i></span>From: United States</p>-->
              <!--</div>-->
              <!--<div class="item col-sm-6">-->
                <!--<p><span class="icon"><i class="ti-briefcase"></i></span>Experiance: 6 Years</p>-->
              <!--</div>-->
              <!--<div class="item col-sm-6">-->
                <!--<p><span class="icon"><i class="ti-time"></i></span>Availability: Full Time</p>-->
              <!--</div>-->
            <!--</div>-->
            <br>
            <!-- <a href="#contact" data-scroll-nav="6" class="bg-button">
              <span>Contact Us</span>
            </a> -->

          </div>
        </div>

        <!-- Skills -->
        <div class="col-lg-5">
          <img alt="Logo Double O" src="@/assets/img/logo.png" srcset="@/assets/img/logo.png, @/assets/img/logo@2x.png 2x" />
        </div>

      </div><!-- /row -->
    </div><!-- /container -->
  </section>
</template>

<script>
export default {
	name: 'About'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
