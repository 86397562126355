import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './registerServiceWorker';

import 'bootstrap';
import VueScrollactive from 'vue-scrollactive';
import { init } from '@/assets/js/main';

Vue.use(VueScrollactive);
Vue.use(init);
Vue.config.productionTip = false;

new Vue({
	router,
	render: h => h(App)
}).$mount('#app');
