<template>
  <section id="clients" class="portfolio clients text-center pb-5" data-scroll-index="3">
    <div class="container">

      <div class="section-head">
        <h3>Clients</h3>
      </div>

      <div class="row">

        <div v-for="(client, i) in clients" v-bind:key="i" class="col-6 col-lg-3 col-md-4 item web">
					<div class="wrap">
          <div class="item-img mb-30 mb-sm-50">

            <img :src="client.image" :alt="client.name" />

          </div>
					</div>
        </div>
      </div>
    </div><!-- /container -->
  </section>
  <!-- ===== End Portfolio ===== -->
</template>

<script>
const getImage = img => require('@/assets/img/clients/' + img)

export default {
	name: 'Clients',
	data: () => ({
		clients: [
			{
				image: getImage('1.png'),
				name: 'Hoàn Mỹ'
			},
			{
				image: getImage('3.png'),
				name: 'Footstep Indochina Travel'
			},
			{
				image: getImage('4.png'),
				name: 'Footstep Vacations'
			},
			{
				image: getImage('tourista.png'),
				name: 'Tourista.vn'
			},
			{
				image: getImage('6.png'),
				name: 'TekHome'
			},
			{
				image: getImage('7.png'),
				name: 'Tâm Dược'
			},
			{
				image: getImage('8.png'),
				name: 'Viên ăn ngủ ngon'
			},
			{
				image: getImage('9.png'),
				name: 'Khám Phá Di Sản'
			},
			{
				image: getImage('10.png'),
				name: 'Sample Store'
			},
			{
				image: getImage('11.png'),
				name: 'Sony'
			},
			{
				image: getImage('2.png'),
				name: 'TNCL'
			},
			{
				image: getImage('12.png'),
				name: 'Asus'
			}
		]
	})
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.wrap{
	padding: 25px;
	background: #fff;
	border-radius: 25px;
	margin-bottom: 1rem;
	filter: grayscale(1);
	transition: all linear 0.3s;
	&:hover{
		filter:grayscale(0);
	}
}
</style>
