import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';

Vue.use(Router);

// scrollBehavior:
// - only available in html5 history mode
// - defaults to no scroll behavior
// - return false to prevent scroll
const scrollBehavior = (to, from, savedPosition) => {
	if (savedPosition) {
		// savedPosition is only available for popstate navigations.
		return savedPosition;
	} else {
		const position = {};
		// new navigation.
		// scroll to anchor by returning the selector
		if (to.hash) {
			position.selector = to.hash;

			// specify offset of the element
			if (to.hash === '#about') {
				position.offset = { y: 100 };
			}
		}
		// check if any matched route config has meta that requires scrolling to top
		if (to.matched.some((m) => m.meta.scrollToTop)) {
			// cords will be used if no selector is provided,
			// or if the selector didn't match any element.
			position.x = 0;
			position.y = 0;
		}
		// if the returned position is falsy or an empty object,
		// will retain current scroll position.
		return position;
	}
};

export default new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior,
	routes: [
		{
			path: '/',
			name: 'home',
			component: Home
		},
		{
			path: '/portfolio',
			name: 'portfolio',
			// route level code-splitting
			// this generates a separate chunk (about.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			component: () =>
				import(/* webpackChunkName: "about" */ './views/Portfolio.vue')
		},
		{
			path: '/about',
			name: 'about',
			// route level code-splitting
			// this generates a separate chunk (about.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			component: () =>
				import(/* webpackChunkName: "about" */ './views/About.vue')
		}
	]
});
