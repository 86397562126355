<template>
  <header id="home" class="header bg-prop section-overlay gradient" data-scroll-index="0">
    <div class="v-middle">
      <div class="container">

        <!-- Caption -->
        <div class="caption text-center">

          <h1>We are Double O</h1>
          <h2>Website design and development services</h2>

        </div><!-- /caption -->
      </div><!-- /container -->
    </div><!-- /v-middle -->
  </header>
</template>

<script>
export default {
	name: 'Intro'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header{
  background-image: url('~@/assets/img/bg1.jpg');
}
</style>
