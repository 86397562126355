import $ from 'jquery'
export function init () {
	$(window).on('load', function () {
		$('.loading').fadeOut(500)
	})
	$(window).on('scroll', function () {
		const o = $(window).scrollTop()
		const a = $('.navbar')
		if (o > 100) {
			a.addClass('nav-scroll')
			$('#logoLight').hide()
			$('#logoDark').show()
		} else {
			a.removeClass('nav-scroll')
			$('#logoLight').show()
			$('#logoDark').hide()
		}
	})
	$(window).on('scroll', function () {
		const t = $('.button-top')
		$(this).scrollTop() >= 700 ? t.show() : t.hide()
	})
}
