<template>
  <section id="services" class="services section-padding text-center" data-scroll-index="2">
    <div class="container">

      <!-- Section Heading  -->
      <div class="section-head">
        <h3>Services</h3>
        <div class="row">
          <div class="col-lg-6 col-md-8 mx-auto">
            <p>We provides a broad range of services to enhance your businesses, and benefits your customers in the world of digital.</p>
          </div>
        </div>
      </div>

      <div class="row">
        <!-- Services Item -->
        <div class="col-lg-4 col-md-6">
          <div class="item mb-md-30 mb-sm-50">
            <span class="icon"><i class="ti-layout-slider"></i></span>
            <h5 class="sub-heading">Development</h5>
            <p>We create modern and responsive websites, portals, e-shops and mobile websites with a strong focuson the user.</p>
          </div>
        </div>

        <!-- Services Item -->
        <div class="col-lg-4 col-md-6">
          <div class="item mb-sm-50">
            <span class="icon"><i class="ti-pie-chart"></i></span>
            <h5 class="sub-heading">Analytics</h5>
            <p>We'll help you to choose the best keywords and then get your website on the first position in the search engines.</p>
          </div>
        </div>
        <!-- Services Item -->
        <div class="col-lg-4 col-md-6">
          <div class="item mb-30 mb-sm-50">
            <span class="icon"><i class="ti-write"></i></span>
            <h5 class="sub-heading">Branding</h5>
            <p>We provide a complete corporate identity including distinguishing logo, name and presentation materials.</p>
          </div>
        </div>

        <!-- Services Item -->
        <div class="col-lg-4 col-md-6">
          <div class="item mb-30 mb-sm-50">
            <span class="icon"><i class="ti-mobile"></i></span>
            <h5 class="sub-heading">Marketing</h5>
            <p>We propose and create successful campaigns supported by all types of online marketing, including social networking, SEO, and PPC advertising.</p>
          </div>
        </div>
        <!-- Services Item -->
        <div class="col-lg-4 col-md-6">
          <div class="item mb-30 mb-sm-50">
            <span class="icon"><i class="ti-desktop"></i></span>
            <h5 class="sub-heading">Graphic and UI/UX</h5>
            <p>We understand the big picture in the UI planning stage, so we design in the context of execution, knowing all the cutting edge capabilities of modern technology.</p>
          </div>
        </div>

        <!-- Services Item -->
        <div class="col-lg-4 col-md-6">
          <div class="item mb-30 mb-sm-50">
            <span class="icon"><i class="ti-pencil-alt"></i></span>
            <h5 class="sub-heading">Creating Content</h5>
            <p>Our copywriters can prepare interesting content that is not only friendly to the search engines, but it is also hard-hitting from the marketing point of view.</p>
          </div>
        </div>

      </div><!-- /row -->
    </div><!-- /container -->
  </section>
</template>

<script>
export default {
	name: 'Services'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
